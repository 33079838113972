    @font-face {
      font-family: 'Space Grotesk';
      font-weight: 300;
      src:
        url('/fonts/SpaceGrotesk-Light.ttf') format('truetype'),
        url('/fonts/SpaceGrotesk-Light.otf') format('otf'),
        url('/fonts/SpaceGrotesk-Light.woff') format('woff')
    }

    @font-face {
      font-family: 'Space Grotesk';
      font-weight: 400;
      src: url('/fonts/SpaceGrotesk-Regular.ttf') format('truetype'), url('/fonts/SpaceGrotesk-Regular.otf') format('otf'), url('/fonts/SpaceGrotesk-Regular.woff') format('woff');
    }

    @font-face {
      font-family: 'Space Grotesk';
      font-weight: 500;
      src: url('/fonts/SpaceGrotesk-Medium.ttf') format('truetype'), url('/fonts/SpaceGrotesk-Medium.otf') format('otf'), url('/fonts/SpaceGrotesk-Medium.woff') format('woff');
    }

    @font-face {
      font-family: 'Space Grotesk';
      font-weight: 600;
      src: url('/fonts/SpaceGrotesk-SemiBold.ttf') format('truetype'), url('/fonts/SpaceGrotesk-SemiBold.otf') format('otf'), url('/fonts/SpaceGrotesk-SemiBold.woff') format('woff');
    }

    @font-face {
      font-family: 'Space Grotesk';
      font-weight: 700;
      src: url('/fonts/SpaceGrotesk-Bold.ttf') format('truetype'), url('/fonts/SpaceGrotesk-Bold.otf') format('otf'), url('/fonts/SpaceGrotesk-Bold.woff') format('woff');
    }
    @font-face {
      font-family: 'GT America';
      font-weight: 400;
      src: url('/fonts/GT-America-Standard-Regular.ttf') format('truetype'), url('/fonts/GT-America-Standard-Regular.woff') format('woff'), url('/fonts/GT-America-Standard-Regular.woff2') format('woff2');
    }